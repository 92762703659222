import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import WelcomeModal from './welcomeModal';
import './Chat.css';

function Chat() {
  const { botId } = useParams();
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [domainName, setDomainName] = useState('')
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const messagesEndRef = useRef(null);
  const currentBotMessage = useRef("");
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const generateSessionId = () => {
      const timestamp = new Date().getTime();
      const randomNum = Math.floor(Math.random() * 10000) + 1;
      return `${timestamp}-${randomNum}`;
    };
    setSessionId(generateSessionId());

    const fetchWelcomeMessage = async () => {
      try {
        const response = await fetch(`/api/widget_configuration/${botId}`);
        const data = await response.json();
        const welcomeMessage = data.welcome_message || "Welcome to your virtual assistant! 😊 How can I assist you today?";
        setMessages([
          {
            text: welcomeMessage,
            from: 'bot'
          }
        ]);
        scrollToBottom();
      } catch (error) {
        console.error('Error fetching welcome message:', error);
        setMessages([
          {
            text: "Failed to load welcome message from server.",
            from: 'bot'
          }
        ]);
      }
    };

    const fetchBot = async () => {
      try {
        const response = await fetch(`/api/bot?bot_id=${botId}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Capitalize the first letter of the domain name and make the rest lowercase
        const formattedDomainName =
          data.domain_name.charAt(0).toUpperCase() + data.domain_name.slice(1).toLowerCase();

        setDomainName(formattedDomainName);
      } catch (error) {
        console.error('Error fetching bot:', error);
      }
    };

    fetchBot();
    fetchWelcomeMessage();
  }, [botId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async () => {
    const userMessage = { text: userInput, from: 'user' };
    setMessages(messages => [...messages, userMessage]);
    setUserInput('');

    setLoading(true);
    currentBotMessage.current = "";

    const maxRetries = 3;
    let retryCount = 0;

    const attemptConnection = () => {
      return new Promise((resolve, reject) => {
        const eventSource = new EventSource(`/api/chatbot_response?user_input=${encodeURIComponent(userInput)}&session_id=${sessionId}&bot_id=${botId}&language=english`);

        let isFirstMessage = true;
        let timeoutId;

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
            if (isFirstMessage) {
              setLoading(false);
              isFirstMessage = false;
              clearTimeout(timeoutId);
            }
            const parsedChunk = chunk.replace(/<newline>/g, '\n');
            currentBotMessage.current += parsedChunk;
            setMessages(messages => {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage && lastMessage.from === 'bot') {
                return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
              } else {
                return [...messages, { text: currentBotMessage.current, from: 'bot' }];
              }
            });
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          if (isFirstMessage) {
            reject(new Error('Failed to get response from server.'));
          }
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.from === 'bot') {
              return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
            } else {
              return [...messages, { text: currentBotMessage.current, from: 'bot' }];
            }
          });
          eventSource.close();
          resolve();
        });

        // Set a timeout to close the connection if no message is received
        timeoutId = setTimeout(() => {
          if (isFirstMessage) {
            eventSource.close();
            reject(new Error('No response received from server.'));
          }
        }, 8000); //
      });
    };

    const retryConnection = async () => {
      while (retryCount < maxRetries) {
        try {
          await attemptConnection();
          return; // Success, exit the retry loop
        } catch (error) {
          retryCount++;
          console.log(`Attempt ${retryCount} failed. Retrying...`);
          if (retryCount >= maxRetries) {
            setLoading(false);
            setMessages(messages => [...messages, { text: `Failed to get response after ${maxRetries} attempts.`, from: 'bot' }]);
            throw error; // Rethrow the error after max retries
          }
        }
      }
    };

    try {
      await retryConnection();
    } catch (error) {
      console.error('Error fetching response:', error);
      setLoading(false);
    }
  };

  const LinkRenderer = (props) => {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  return (
    <div className="chat-parent">
      {showModal && <WelcomeModal onClose={closeModal} language="de"/>}
      <div className='logo-messages-container'>
        <div className='logo-container'>
          <div className='test-env'>AI Chatbot Secure Test Environment</div>
          <div className='test-env'>for {domainName}</div>
          <a href="http://swiss-bot.com/" target="_blank" rel="noopener noreferrer">
            <div className='powered-by'>Powered by Swiss AI Chatbot Factory</div>
          </a>
        </div>
        <div className="message-container">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.from === 'user' ? 'user-message' : 'bot-message'}`}>
              {msg.from === 'bot' && (
                <div className="scf-icon-container">
                  <div className='bot-left-icon'>
                    <div className='bot-left-icon-text'>
                      <p style={{'fontSize': '12px'}}>AI</p>
                      <p>Chatbot</p>
                    </div>
                  </div>
                </div>
              )}
              <div className={msg.from === 'user' ? 'user-chat-text' : 'bot-chat-text'}>
                <ReactMarkdown
                  components={{
                    a: LinkRenderer
                  }}
                  >{msg.text}
              </ReactMarkdown>
              </div>
              {msg.from === 'user' && (
                <div className="user-icon-container">
                  <img src="../user-icon.png" alt="User Icon" className='user-icon' />
                </div>
              )}
            </div>
          ))}
          {loading && <div className="loader"></div>}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' ? sendMessage() : null}
        />
        <button onClick={sendMessage} disabled={!userInput.trim()}>
          <FontAwesomeIcon icon={faLocationArrow} className='send-icon'/>
        </button>
      </div>
    </div>
  );
}

export default Chat;
